import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { ModalModule } from "ngx-bootstrap/modal";
import { TabsModule } from "ngx-bootstrap/tabs";
import { TimepickerModule } from "ngx-bootstrap/timepicker";
import { NgModule, ModuleWithProviders } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";
import { DataTablesModule } from "angular-datatables";
import { ChartistModule } from "ng-chartist";
import { TypeaheadModule } from "ngx-bootstrap/typeahead";
import { PopoverModule } from "ngx-bootstrap/popover";

import { CamerasComponent } from "./cameras.component";
import { CamerasService, CameraConfigService, ICameraConfigService } from "./cameras.service";
import { CameracreateComponent } from "./cameracreate.component";
import { CameradetailComponent } from "./cameradetail.component";
import { TranslateModule } from "@ngx-translate/core";
import { OAuthModule } from "@impacgroup/angular-oauth-base";
import { Route, RouterModule } from "@angular/router";
import { CamerasMainComponent } from "./cameras.main.component";
import { CameraDataAndSettingsComponent } from "./components/cameradataandsettings.component";
import { Cameradetail4GProComponent } from "./cameradetail4gpro.component";
import { Cameras4GProService } from "./cameras4gpro.service";
import { Camera4GProConfigRepository, Cameras4GProRepository, ICamera4GProConfigRepository } from "./cameras4gpro.repository";
import { CameraDataAndSettings4GProComponent } from "./components/cameradataandsettings4gpro.component";

@NgModule({
    declarations: [CamerasMainComponent, CamerasComponent, CameracreateComponent, CameradetailComponent, CameraDataAndSettingsComponent, Cameradetail4GProComponent, CameraDataAndSettings4GProComponent],
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        DataTablesModule,
        TypeaheadModule.forRoot(),
        PopoverModule.forRoot(),
        BsDropdownModule,
        ModalModule,
        NgSelectModule,
        TabsModule,
        TimepickerModule,
        TranslateModule,
        OAuthModule,
        BsDatepickerModule,
        ChartistModule
    ],
    exports: [],
    providers: []
})
export class CamerasModule {
    static forRoot(cameraConfig: ICameraConfigService | ICamera4GProConfigRepository): ModuleWithProviders<CamerasModule> {
        // returns the services as singletons throughout the app (for lazy loading purposes)
        return {
            ngModule: CamerasModule,
            providers: [
                CamerasService,
                {
                    provide: CameraConfigService,
                    useValue: cameraConfig
                },

                Cameras4GProService,
                Cameras4GProRepository,

                {
                    provide: Camera4GProConfigRepository,
                    useValue: cameraConfig
                }
            ]
        };
    }

    static getRoutes(): Route[] {
        return [
            {
                path: "",
                component: CamerasMainComponent,
                children: [
                    {
                        path: "",
                        component: CamerasComponent
                    },
                    {
                        path: "add",
                        component: CameracreateComponent
                    },
                    {
                        path: "cloud_4g_v1/:id",
                        component: CameradetailComponent,
                        data: { productType: "cloud_4g_v1" }
                    },
                    {
                        path: "cloud_4g_v2/:id",
                        component: CameradetailComponent,
                        data: { productType: "cloud_4g_v2" }
                    },
                    {
                        path: "cloud_4g_pro/:id",
                        component: Cameradetail4GProComponent,
                        data: { productType: "cloud_4g_pro" }
                    },
                    {
                        path: "cloud_4g_pro_wa/:id",
                        component: Cameradetail4GProComponent,
                        data: { productType: "cloud_4g_pro_wa" }
                    },
                    {
                        path: "cloud_4g_pro_rc/:id",
                        component: Cameradetail4GProComponent,
                        data: { productType: "cloud_4g_pro_rc" }
                    },
                    {
                        path: "cloud_4g_pro_rc_wa/:id",
                        component: Cameradetail4GProComponent,
                        data: { productType: "cloud_4g_pro_rc_wa" }
                    },
                    {
                        path: "cloud_4g_pro_mini/:id",
                        component: Cameradetail4GProComponent,
                        data: { productType: "cloud_4g_pro_mini" }
                    }
                ]
            }
        ];
    }
}

import { HttpClient } from "@angular/common/http";
import { Injectable, InjectionToken, Inject } from "@angular/core";
import { Observable } from "rxjs";
import { OAuthStorageAdapter } from "@impacgroup/angular-oauth-base";
import { map } from "rxjs/operators";
import { Expose, Type, plainToInstance } from "class-transformer";
import { V2AdminMediaFavoriteCopyListResponseDTO, V2AdminMediaFavoriteCopyUpdateRequestDTO, V2AdminMediaFavoriteCopyUserCreateRequestDTO, V2AdminMediaFavoriteCopyUserDeleteRequestDTO } from "@impacgroup/doerr-wildkamera-api-dtos";

export interface IMediaFavoriteCopiesConfigRepository {
    mediaFavoriteCopiesAPI: string;
    utcDateFormat: string;
}

export const MediaFavoriteCopiesConfigRepository = new InjectionToken<IMediaFavoriteCopiesConfigRepository>("CameraConfig");

@Injectable()
export class MediaFavoriteCopiesRepository {
    public UTCDATEFORMAT: string = "";

    constructor(@Inject(MediaFavoriteCopiesConfigRepository) private mediaFavoriteCopiesConfig: IMediaFavoriteCopiesConfigRepository, private http: HttpClient, private oauthStorageAdapter: OAuthStorageAdapter) {
        this.UTCDATEFORMAT = this.mediaFavoriteCopiesConfig.utcDateFormat;
    }

    public list(): Observable<V2AdminMediaFavoriteCopyListResponseDTO[]> {
        return this.http
            .get<any>(this.getCopyPairsServiceUrl(""), {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON(),
                observe: "response"
            })
            .pipe(
                map((result) => {
                    const body = result.body as any[];
                    return plainToInstance(V2AdminMediaFavoriteCopyListResponseDTO, body);
                })
            );
    }

    public createMediaCopyUser(dto: V2AdminMediaFavoriteCopyUserCreateRequestDTO): Observable<V2AdminMediaFavoriteCopyListResponseDTO[]> {
        return this.http
            .post<any>(this.getCopyPairsServiceUrl(""), dto, {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON(),
                observe: "response"
            })
            .pipe(
                map((result) => {
                    const body = result.body as any[];
                    return plainToInstance(V2AdminMediaFavoriteCopyListResponseDTO, body);
                })
            );
    }

    public deleteMediaCopyUser(dto: V2AdminMediaFavoriteCopyUserDeleteRequestDTO): Observable<V2AdminMediaFavoriteCopyListResponseDTO[]> {
        return this.http
            .delete<any>(this.getCopyPairsServiceUrl(dto._id), {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON(),
                observe: "response"
            })
            .pipe(
                map((result) => {
                    const body = result.body as any[];
                    return plainToInstance(V2AdminMediaFavoriteCopyListResponseDTO, body);
                })
            );
    }

    public updateMediaCopyPairs(dto: V2AdminMediaFavoriteCopyUpdateRequestDTO): Observable<V2AdminMediaFavoriteCopyListResponseDTO[]> {
        return this.http
            .patch<any>(this.getCopyPairsServiceUrl(dto._id), dto, {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON(),
                observe: "response"
            })
            .pipe(
                map((result) => {
                    const body = result.body as any[];
                    return plainToInstance(V2AdminMediaFavoriteCopyListResponseDTO, body);
                })
            );
    }

    private getCopyPairsServiceUrl(postfix: string): string {
        return this.mediaFavoriteCopiesConfig.mediaFavoriteCopiesAPI + postfix;
    }
}

export class PermissionsConfig {
    static readonly PERMISSIONS = {
        AUTHENTICATED: {
            andScopes: ["authenticated"],
            orScopes: []
        },
        GLOBAL: {
            // User may switch organizations
            andScopes: ["authenticated", "admin.organization.read", "global"],
            orScopes: []
        },
        "MENU.USERMANAGEMENT": {
            andScopes: ["authenticated"],
            orScopes: ["admin.user.read", "admin.role.read", "admin.organization.read"]
        },
        "MENU.USERMANAGEMENT.USERS": {
            andScopes: ["authenticated"],
            orScopes: ["admin.user.read"]
        },
        "MENU.USERMANAGEMENT.ROLES": {
            andScopes: ["authenticated"],
            orScopes: ["admin.role.read"]
        },
        "MENU.USERMANAGEMENT.ORGANIZATIONS": {
            andScopes: ["authenticated", "admin.organization.read", "global"],
            orScopes: []
        },
        "MENU.LANGUAGES": {
            andScopes: ["authenticated"],
            orScopes: ["admin.language.read"]
        },
        "MENU.MAIL": {
            andScopes: ["authenticated"],
            orScopes: ["admin.mailaccount.read", "admin.mailtemplate.read"]
        },
        "MENU.MAIL.MAILACCOUNTS": {
            andScopes: ["authenticated"],
            orScopes: ["admin.mailaccount.read"]
        },
        "MENU.MAIL.MAILTEMPLATES": {
            andScopes: ["authenticated"],
            orScopes: ["admin.mailtemplate.read"]
        },
        "MENU.REGISTRATION": {
            andScopes: ["authenticated"],
            orScopes: ["admin.registrationconfig.read"]
        },
        "MENU.FIREBASE": {
            andScopes: ["authenticated"],
            orScopes: ["admin.firebaseconfig.read", "admin.firebasedevice.read", "admin.firebasenotification.read", "admin.firebasenotificationtemplate.read"]
        },
        "MENU.FIREBASE.FIREBASECONFIG": {
            andScopes: ["authenticated"],
            orScopes: ["admin.firebaseconfig.read"]
        },
        "MENU.FIREBASE.FIREBASEDEVICES": {
            andScopes: ["authenticated"],
            orScopes: ["admin.firebasedevice.read"]
        },
        "MENU.FIREBASE.FIREBASENOTIFICATIONS": {
            andScopes: ["authenticated"],
            orScopes: ["admin.firebasenotification.read"]
        },
        "MENU.FIREBASE.FIREBASENOTIFICATIONTEMPLATES": {
            andScopes: ["authenticated"],
            orScopes: ["admin.firebasenotificationtemplate.read"]
        },
        "MENU.PAYMENT": {
            andScopes: ["authenticated"],
            orScopes: [
                "admin.appleconfig.read",
                "admin.googleconfig.read",
                "admin.paypalconfig.read",
                "admin.paypalpayment.read",
                "admin.paypalbillingplan.read",
                "admin.paypalbillingagreement.read",
                "admin.klarnaconfig.read",
                "admin.klarnaaboplan.read",
                "admin.klarnaaboagreement.read"
            ] // TODO: add another scopes here
        },
        "MENU.PAYMENT.APPLECONFIG": {
            andScopes: ["authenticated"],
            orScopes: ["admin.appleconfig.read"]
        },
        "MENU.PAYMENT.GOOGLECONFIG": {
            andScopes: ["authenticated"],
            orScopes: ["admin.googleconfig.read"]
        },
        "MENU.PAYMENT.PAYPALCONFIG": {
            andScopes: ["authenticated"],
            orScopes: ["admin.paypalconfig.read"]
        },
        "MENU.PAYMENT.PAYPALPAYMENTS": {
            andScopes: ["authenticated"],
            orScopes: ["admin.paypalpayment.read"]
        },
        "MENU.PAYMENT.PAYPALBILLINGPLANS": {
            andScopes: ["authenticated"],
            orScopes: ["admin.paypalbillingplan.read"]
        },
        "MENU.PAYMENT.PAYPALBILLINGAGREEMENTS": {
            andScopes: ["authenticated"],
            orScopes: ["admin.paypalbillingagreement.read"]
        },
        "MENU.PAYMENT.KLARNACONFIG": {
            andScopes: ["authenticated"],
            orScopes: ["admin.klarnaconfig.read"]
        },
        "MENU.PAYMENT.KLARNAABOPLANS": {
            andScopes: ["authenticated"],
            orScopes: ["admin.klarnaaboplan.read"]
        },
        "MENU.PAYMENT.KLARNAABOAGREEMENTS": {
            andScopes: ["authenticated"],
            orScopes: ["admin.klarnaaboagreement.read"]
        },
        "MENU.SMS": {
            andScopes: ["authenticated"],
            orScopes: ["admin.websmsconfig.read", "admin.websmsnotification.read"]
        },
        "MENU.SMS.WEBSMSCONFIG": {
            andScopes: ["authenticated"],
            orScopes: ["admin.websmsconfig.read"]
        },
        "MENU.SMS.WEBSMSNOTIFICATIONS": {
            andScopes: ["authenticated"],
            orScopes: ["admin.websmsnotification.read"]
        },
        "MENU.SYSTEMHEALTH": {
            andScopes: ["authenticated"],
            orScopes: ["admin.systemlog.read", "admin.systemjob.read", "admin.systemresource.read", "admin.systemerror.read"] // TODO: add scopes from the other modules
        },
        "MENU.SYSTEMHEALTH.SYSTEMLOGMESSAGES": {
            andScopes: ["authenticated"],
            orScopes: ["admin.systemlog.read"]
        },
        "MENU.SYSTEMHEALTH.SYSTEMJOBPERFORMANCES": {
            andScopes: ["authenticated"],
            orScopes: ["admin.systemjob.read"]
        },
        "MENU.SYSTEMHEALTH.SYSTEMRESOURCESNAPSHOTS": {
            andScopes: ["authenticated"],
            orScopes: ["admin.systemresource.read"]
        },
        "MENU.SYSTEMHEALTH.SYSTEMERRORS": {
            andScopes: ["authenticated"],
            orScopes: ["admin.systemerror.read"]
        },
        "MENU.IMEIS": {
            andScopes: ["authenticated"],
            orScopes: ["admin.imei.read"]
        },
        "MENU.FIRMWARE": {
            andScopes: ["authenticated"],
            orScopes: ["admin.firmware.read", "admin.apn.read"]
        },
        "MENU.FIRMWARES": {
            andScopes: ["authenticated"],
            orScopes: ["admin.firmware.read"]
        },
        "MENU.APNS": {
            andScopes: ["authenticated"],
            orScopes: ["admin.apn.read"]
        },
        "MENU.CAMERAS": {
            andScopes: ["authenticated"],
            orScopes: ["admin.camera.read", "admin.camera.owner.read"]
        },
        "MENU.ABOUSERS": {
            andScopes: ["authenticated"],
            orScopes: ["admin.abo.read"]
        },
        "MENU.CRASHLOGS": {
            andScopes: ["authenticated"],
            orScopes: ["admin.crashlogapplication.read", "admin.crashlogmessage.read", "admin.crashlogerror.read"] // TODO: add scopes from the other modules
        },
        "MENU.CRASHLOGS.CRASHLOGAPPLICATIONS": {
            andScopes: ["authenticated"],
            orScopes: ["admin.crashlogapplication.read"]
        },
        "MENU.CRASHLOGS.CRASHLOGMESSAGES": {
            andScopes: ["authenticated"],
            orScopes: ["admin.crashlogmessage.read"]
        },
        "MENU.CRASHLOGS.CRASHLOGERRORS": {
            andScopes: ["authenticated"],
            orScopes: ["admin.crashlogerror.read"]
        },
        "MENU.TEXTPOOLS": {
            andScopes: ["authenticated"],
            orScopes: ["admin.textpool.read"]
        },
        "MENU.STOLENCAMERAS": {
            andScopes: ["authenticated"],
            orScopes: ["admin.stolencam.read"]
        },
        "MENU.MEDIAFAVORITECOPIES": {
            andScopes: ["authenticated"],
            orScopes: ["admin.mediafavoritecopy.read"]
        },
        "USERMANAGEMENT.USERS.ADD": {
            andScopes: ["authenticated"],
            orScopes: ["admin.user.create"]
        },
        "USERMANAGEMENT.USERS.LIST": {
            andScopes: ["authenticated"],
            orScopes: ["admin.user.read"]
        },
        "USERMANAGEMENT.USERS.EDIT": {
            andScopes: ["authenticated"],
            orScopes: ["admin.user.update"]
        },
        "USERMANAGEMENT.USERS.VIEW": {
            andScopes: ["authenticated"],
            orScopes: ["admin.user.read"]
        },
        "USERMANAGEMENT.USERS.DELETE": {
            andScopes: ["authenticated"],
            orScopes: ["admin.user.delete"]
        },
        "USERMANAGEMENT.ROLES.ADD": {
            andScopes: ["authenticated"],
            orScopes: ["admin.role.create"]
        },
        "USERMANAGEMENT.ROLES.LIST": {
            andScopes: ["authenticated"],
            orScopes: ["admin.role.read"]
        },
        "USERMANAGEMENT.ROLES.EDIT": {
            andScopes: ["authenticated"],
            orScopes: ["admin.role.update"]
        },
        "USERMANAGEMENT.ROLES.DELETE": {
            andScopes: ["authenticated"],
            orScopes: ["admin.role.delete"]
        },
        "USERMANAGEMENT.ORGANIZATIONS.ADD": {
            andScopes: ["authenticated", "global"],
            orScopes: ["admin.organization.create"]
        },
        "USERMANAGEMENT.ORGANIZATIONS.LIST": {
            andScopes: ["authenticated", "global"],
            orScopes: ["admin.organization.read"]
        },
        "USERMANAGEMENT.ORGANIZATIONS.EDIT": {
            andScopes: ["authenticated", "global"],
            orScopes: ["admin.organization.update"]
        },
        "USERMANAGEMENT.ORGANIZATIONS.DELETE": {
            andScopes: ["authenticated", "global"],
            orScopes: ["admin.organization.delete"]
        },
        "LANGUAGES.ADD": {
            andScopes: ["authenticated"],
            orScopes: ["admin.language.create"]
        },
        "LANGUAGES.LIST": {
            andScopes: ["authenticated"],
            orScopes: ["admin.language.read"]
        },
        "LANGUAGES.EDIT": {
            andScopes: ["authenticated"],
            orScopes: ["admin.language.update"]
        },
        "LANGUAGES.DELETE": {
            andScopes: ["authenticated"],
            orScopes: ["admin.language.delete"]
        },
        "MAIL.MAILTEMPLATES.ADD": {
            andScopes: ["authenticated"],
            orScopes: ["admin.mailtemplate.create"]
        },
        "MAIL.MAILTEMPLATES.LIST": {
            andScopes: ["authenticated"],
            orScopes: ["admin.mailtemplate.read"]
        },
        "MAIL.MAILTEMPLATES.EDIT": {
            andScopes: ["authenticated"],
            orScopes: ["admin.mailtemplate.update"]
        },
        "MAIL.MAILTEMPLATES.DELETE": {
            andScopes: ["authenticated"],
            orScopes: ["admin.mailtemplate.delete"]
        },
        "MAIL.MAILACCOUNTS.ADD": {
            andScopes: ["authenticated"],
            orScopes: ["admin.mailaccount.create"]
        },
        "MAIL.MAILACCOUNTS.LIST": {
            andScopes: ["authenticated"],
            orScopes: ["admin.mailaccount.read"]
        },
        "MAIL.MAILACCOUNTS.EDIT": {
            andScopes: ["authenticated"],
            orScopes: ["admin.mailaccount.update"]
        },
        "MAIL.MAILACCOUNTS.DELETE": {
            andScopes: ["authenticated"],
            orScopes: ["admin.mailaccount.delete"]
        },
        "REGISTRATIONCONFIG.EDIT": {
            andScopes: ["authenticated"],
            orScopes: ["admin.registrationconfig.update"]
        },
        "FIREBASE.FIREBASECONFIG.EDIT": {
            andScopes: ["authenticated"],
            orScopes: ["admin.firebaseconfig.update"]
        },
        "FIREBASE.FIREBASEDEVICES.LIST": {
            andScopes: ["authenticated"],
            orScopes: ["admin.firebasedevice.read"]
        },
        "FIREBASE.FIREBASEDEVICES.VIEW": {
            andScopes: ["authenticated"],
            orScopes: ["admin.firebasedevice.read"]
        },
        "FIREBASE.FIREBASEDEVICES.DELETE": {
            andScopes: ["authenticated"],
            orScopes: ["admin.firebasedevice.delete"]
        },
        "FIREBASE.FIREBASENOTIFICATIONS.LIST": {
            andScopes: ["authenticated"],
            orScopes: ["admin.firebasenotification.read"]
        },
        "FIREBASE.FIREBASENOTIFICATIONS.SEND": {
            andScopes: ["authenticated"],
            orScopes: ["admin.firebasenotification.send"]
        },
        "FIREBASE.FIREBASENOTIFICATIONS.VIEW": {
            andScopes: ["authenticated"],
            orScopes: ["admin.firebasenotification.read"]
        },
        "FIREBASE.FIREBASENOTIFICATIONTEMPLATES.ADD": {
            andScopes: ["authenticated"],
            orScopes: ["admin.firebasenotificationtemplate.create"]
        },
        "FIREBASE.FIREBASENOTIFICATIONTEMPLATES.LIST": {
            andScopes: ["authenticated"],
            orScopes: ["admin.firebasenotificationtemplate.read"]
        },
        "FIREBASE.FIREBASENOTIFICATIONTEMPLATES.EDIT": {
            andScopes: ["authenticated"],
            orScopes: ["admin.firebasenotificationtemplate.update"]
        },
        "FIREBASE.FIREBASENOTIFICATIONTEMPLATES.DELETE": {
            andScopes: ["authenticated"],
            orScopes: ["admin.firebasenotificationtemplate.delete"]
        },
        "FIREBASE.FIREBASENOTIFICATIONTEMPLATES.PUSHDATACONTENT": {
            // add or edit push data object
            andScopes: ["authenticated"],
            orScopes: ["admin.firebasenotificationtemplate.pushdatacontent"]
        },
        "FIREBASE.FIREBASENOTIFICATIONTEMPLATES.PUSHNONSILENT": {
            // add or edit silent option (default is true)
            andScopes: ["authenticated"],
            orScopes: ["admin.firebasenotificationtemplate.pushdatacontent"]
        },
        "PAYMENT.APPLECONFIG.EDIT": {
            andScopes: ["authenticated"],
            orScopes: ["admin.appleconfig.update"]
        },
        "PAYMENT.GOOGLECONFIG.EDIT": {
            andScopes: ["authenticated"],
            orScopes: ["admin.googleconfig.update"]
        },
        "PAYMENT.PAYPALCONFIG.EDIT": {
            andScopes: ["authenticated"],
            orScopes: ["admin.paypalconfig.update"]
        },
        "PAYMENT.PAYPALPAYMENTS.LIST": {
            andScopes: ["authenticated"],
            orScopes: ["admin.paypalpayment.read"]
        },
        "PAYMENT.PAYPALPAYMENTS.VIEW": {
            andScopes: ["authenticated"],
            orScopes: ["admin.paypalpayment.read"]
        },
        "PAYMENT.PAYPALBILLINGPLANS.LIST": {
            andScopes: ["authenticated"],
            orScopes: ["admin.paypalbillingplan.read"]
        },
        "PAYMENT.PAYPALBILLINGPLANS.EDIT": {
            andScopes: ["authenticated"],
            orScopes: ["admin.paypalbillingplan.update"]
        },
        "PAYMENT.PAYPALBILLINGPLANS.ADD": {
            andScopes: ["authenticated"],
            orScopes: ["admin.paypalbillingplan.create"]
        },
        "PAYMENT.PAYPALBILLINGAGREEMENS.LIST": {
            andScopes: ["authenticated"],
            orScopes: ["admin.paypalbillingagreement.read"]
        },
        "PAYMENT.PAYPALBILLINGAGREEMENS.VIEW": {
            andScopes: ["authenticated"],
            orScopes: ["admin.paypalbillingagreement.read"]
        },
        "PAYMENT.PAYPALBILLINGAGREEMENS.CANCEL": {
            andScopes: ["authenticated"],
            orScopes: ["admin.paypalbillingagreement.cancel"]
        },
        "PAYMENT.KLARNACONFIG.EDIT": {
            andScopes: ["authenticated"],
            orScopes: ["admin.klarnaconfig.update"]
        },
        "PAYMENT.KLARNAABOPLANS.ADD": {
            andScopes: ["authenticated"],
            orScopes: ["admin.klarnaaboplan.create"]
        },
        "PAYMENT.KLARNAABOPLANS.LIST": {
            andScopes: ["authenticated"],
            orScopes: ["admin.klarnaaboplan.read"]
        },
        "PAYMENT.KLARNAABOPLANS.EDIT": {
            andScopes: ["authenticated"],
            orScopes: ["admin.klarnaaboplan.update"]
        },
        "PAYMENT.KLARNAABOPLANS.DELETE": {
            andScopes: ["authenticated"],
            orScopes: ["admin.klarnaaboplan.delete"]
        },
        "PAYMENT.KLARNAABOAGREEMENTS.LIST": {
            andScopes: ["authenticated"],
            orScopes: ["admin.klarnaaboagreement.read"]
        },
        "PAYMENT.KLARNAABOAGREEMENTS.VIEW": {
            andScopes: ["authenticated"],
            orScopes: ["admin.klarnaaboagreement.read"]
        },
        "PAYMENT.KLARNAABOAGREEMENTS.CANCEL": {
            andScopes: ["authenticated"],
            orScopes: ["admin.klarnaaboagreement.cancel"]
        },
        "SYSTEMHEALTH.SYSTEMLOGMESSAGES.LIST": {
            andScopes: ["authenticated"],
            orScopes: ["admin.systemlog.read"]
        },
        "SYSTEMHEALTH.SYSTEMJOBPERFORMANCES.LIST": {
            andScopes: ["authenticated"],
            orScopes: ["admin.systemjob.read"]
        },
        "SYSTEMHEALTH.SYSTEMRESOURCESNAPSHOTS.STATISTICS": {
            andScopes: ["authenticated"],
            orScopes: ["admin.systemresource.read"]
        },
        "SYSTEMHEALTH.SYSTEMERRORS.LIST": {
            andScopes: ["authenticated"],
            orScopes: ["admin.systemerror.read"]
        },
        "SYSTEMHEALTH.SYSTEMERRORS.EDIT": {
            andScopes: ["authenticated"],
            orScopes: ["admin.systemerror.update"]
        },
        "IMEIS.ADD": {
            andScopes: ["authenticated"],
            orScopes: ["admin.imei.create"]
        },
        "IMEIS.LIST": {
            andScopes: ["authenticated"],
            orScopes: ["admin.imei.read"]
        },
        "IMEIS.EDIT": {
            andScopes: ["authenticated"],
            orScopes: ["admin.imei.update"]
        },
        "IMEIS.DELETE": {
            andScopes: ["authenticated"],
            orScopes: ["admin.imei.delete"]
        },
        "CAMERAS.LIST": {
            andScopes: ["authenticated"],
            orScopes: ["admin.camera.read", "admin.camera.owner.read"]
        },
        "CAMERAS.ADD": {
            andScopes: ["authenticated"],
            orScopes: ["admin.camera.create"]
        },
        "CAMERAS.EDIT": {
            andScopes: ["authenticated"],
            orScopes: ["admin.camera.update"]
        },
        "CAMERAS.EDIT.BASEDATA": {
            andScopes: ["authenticated"],
            orScopes: ["admin.camera.update.basedata"]
        },
        "CAMERAS.DELETE": {
            andScopes: ["authenticated"],
            orScopes: ["admin.camera.delete"]
        },
        "SMS.WEBSMSCONFIG.EDIT": {
            andScopes: ["authenticated"],
            orScopes: ["admin.websmsconfig.update"]
        },
        "SMS.WEBSMSNOTIFICATIONS.LIST": {
            andScopes: ["authenticated"],
            orScopes: ["admin.websmsnotification.read"]
        },
        "SMS.WEBSMSNOTIFICATIONS.SEND": {
            andScopes: ["authenticated"],
            orScopes: ["admin.websmsnotification.send"]
        },
        "SMS.WEBSMSNOTIFICATIONS.VIEW": {
            andScopes: ["authenticated"],
            orScopes: ["admin.websmsnotification.read"]
        },
        "ABOUSERS.LIST": {
            andScopes: ["authenticated"],
            orScopes: ["admin.abo.read"]
        },
        "ABOUSERS.VIEW": {
            andScopes: ["authenticated"],
            orScopes: ["admin.abo.read"]
        },
        "FIRMWARES.ADD": {
            andScopes: ["authenticated"],
            orScopes: ["admin.firmware.create"]
        },
        "FIRMWARES.LIST": {
            andScopes: ["authenticated"],
            orScopes: ["admin.firmware.read"]
        },
        "FIRMWARES.EDIT": {
            andScopes: ["authenticated"],
            orScopes: ["admin.firmware.update"]
        },
        "FIRMWARES.DELETE": {
            andScopes: ["authenticated"],
            orScopes: ["admin.firmware.delete"]
        },
        "APNS.LIST": {
            andScopes: ["authenticated"],
            orScopes: ["admin.apn.read"]
        },
        "APNS.EDIT": {
            andScopes: ["authenticated"],
            orScopes: ["admin.apn.update"]
        },
        "CRASHLOGAPPLICATION.ADD": {
            andScopes: ["authenticated"],
            orScopes: ["admin.crashlogapplication.create"]
        },
        "CRASHLOGAPPLICATION.LIST": {
            andScopes: ["authenticated"],
            orScopes: ["admin.crashlogapplication.read"]
        },
        "CRASHLOGAPPLICATION.EDIT": {
            andScopes: ["authenticated"],
            orScopes: ["admin.crashlogapplication.update"]
        },
        "CRASHLOGAPPLICATION.DELETE": {
            andScopes: ["authenticated"],
            orScopes: ["admin.crashlogapplication.delete"]
        },
        "CRASHLOGMESSAGES.LIST": {
            andScopes: ["authenticated"],
            orScopes: ["admin.crashlogmessage.read"]
        },
        "CRASHLOGERRORS.LIST": {
            andScopes: ["authenticated"],
            orScopes: ["admin.crashlogerror.read"]
        },
        "CRASHLOGERRORS.EDIT": {
            andScopes: ["authenticated"],
            orScopes: ["admin.crashlogerror.update"]
        },
        "TEXTPOOLS.ADD": {
            andScopes: ["authenticated"],
            orScopes: ["admin.textpool.create"]
        },
        "TEXTPOOLS.LIST": {
            andScopes: ["authenticated"],
            orScopes: ["admin.textpool.read"]
        },
        "TEXTPOOLS.EDIT": {
            andScopes: ["authenticated"],
            orScopes: ["admin.textpool.update"]
        },
        "TEXTPOOLS.DELETE": {
            andScopes: ["authenticated"],
            orScopes: ["admin.textpool.delete"]
        },
        "TEXTPOOLS.TEXT.ADD": {
            andScopes: ["authenticated"],
            orScopes: ["admin.textpool.texts.add"]
        },
        "TEXTPOOLS.TEXT.DELETE": {
            andScopes: ["authenticated"],
            orScopes: ["admin.textpool.texts.delete"]
        },
        "STOLENCAMERAS.ADD": {
            andScopes: ["authenticated"],
            orScopes: ["admin.stolencam.create"]
        },
        "STOLENCAMERAS.LIST": {
            andScopes: ["authenticated"],
            orScopes: ["admin.stolencam.read"]
        },
        "STOLENCAMERAS.EDIT": {
            andScopes: ["authenticated"],
            orScopes: ["admin.stolencam.update"]
        },
        "STOLENCAMERAS.DELETE": {
            andScopes: ["authenticated"],
            orScopes: ["admin.stolencam.delete"]
        },
        "MEDIAFAVORITECOPIES.ADD": {
            andScopes: ["authenticated"],
            orScopes: ["admin.mediafavoritecopy.create"]
        },
        "MEDIAFAVORITECOPIES.LIST": {
            andScopes: ["authenticated"],
            orScopes: ["admin.mediafavoritecopy.read"]
        },
        "MEDIAFAVORITECOPIES.EDIT": {
            andScopes: ["authenticated"],
            orScopes: ["admin.mediafavoritecopy.update"]
        },
        "MEDIAFAVORITECOPIES.DELETE": {
            andScopes: ["authenticated"],
            orScopes: ["admin.mediafavoritecopy.delete"]
        }
    };
}

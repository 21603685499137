<ng-template #addUserModalTemplate>
    <div class="modal-header">
        <h4 class="modal-title">{{ "mediafavoritecopies.list.addUserModal.title" | translate }}</h4>
    </div>
    <div class="modal-body row">
        <div class="col-md-12">
            <div class="form-group">
                <label>{{ "mediafavoritecopies.list.addUserModal.email" | translate }}</label>
                <input name="userEmailToAdd" type="text" class="form-control" [(ngModel)]="userEmailToAdd" />
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-link" (click)="addUser()">{{ "mediafavoritecopies.list.add" | translate }}</button>
        <button class="btn btn-link" (click)="addUserModalRef?.hide()">{{ "global.buttons.close" | translate }}</button>
    </div>
</ng-template>

<div class="content__inner">
    <header class="content__title">
        <h1>{{ "mediafavoritecopies.list.title" | translate }}</h1>
        <small>{{ "mediafavoritecopies.list.subtitle" | translate }}</small>

        <div class="actions">
            <button *appHasPermission="'MEDIAFAVORITECOPIES.ADD'" (click)="openAddUserModal()" class="btn btn-success btn--icon-text" type="button">
                <i class="zmdi zmdi-collection-plus"></i> {{ "mediafavoritecopies.list.add" | translate }}
            </button>
        </div>
    </header>

    <div class="card" *appHasPermission="'MEDIAFAVORITECOPIES.LIST'">
        <div class="card-body">
            <h4 class="card-title">{{ "mediafavoritecopies.list.card.title" | translate }}</h4>

            <div *ngFor="let mediaFavoriteCopyObject of mediaFavoriteCopyObjects">
                <div class="userObjectBar">
                    <button (click)="showHideImeiCopyPairs(mediaFavoriteCopyObject._id ?? '')" class="btn btn-success btn--icon-text expandButton" type="button">
                        <i [ngClass]="mediaFavoriteCopyObject.show ? 'zmdi zmdi-chevron-down' : 'zmdi zmdi-chevron-right'"></i> {{ mediaFavoriteCopyObject.user?.email }}
                    </button>
                    <i (click)="deleteUser(mediaFavoriteCopyObject._id)" class="zmdi zmdi-delete zmdi-hc-fw deleteIcon" *appHasPermission="'MEDIAFAVORITECOPIES.DELETE'"></i>
                </div>
                <div *ngIf="mediaFavoriteCopyObject.show">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th>{{ "mediafavoritecopies.props.fromImei" | translate }}</th>
                                <th>{{ "mediafavoritecopies.props.toImei" | translate }}</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody *ngIf="mediaFavoriteCopyObject.imeiCopyPairs?.length != 0">
                            <tr *ngFor="let imeiCopyPair of mediaFavoriteCopyObject.imeiCopyPairs; index as idx">
                                <td>
                                    <input name="{{ mediaFavoriteCopyObject._id }}_{{ idx }}_fromImei" type="text" class="form-control" [(ngModel)]="imeiCopyPair.fromImei" [readonly]="!imeiCopyPair.editable" />
                                </td>
                                <td>
                                    <input name="{{ mediaFavoriteCopyObject._id }}_{{ idx }}_toImei" type="text" class="form-control" [(ngModel)]="imeiCopyPair.toImei" [readonly]="!imeiCopyPair.editable" />
                                </td>
                                <td class="text-right nowrap">
                                    <i (click)="editImeiCopyPair(idx, mediaFavoriteCopyObject._id ?? '')" class="zmdi zmdi-edit zmdi-hc-fw tableIcon" *appHasPermission="'MEDIAFAVORITECOPIES.EDIT'"></i>
                                    <i (click)="deleteImeiCopyPair(idx, mediaFavoriteCopyObject._id ?? '')" class="zmdi zmdi-delete zmdi-hc-fw tableIcon" *appHasPermission="'MEDIAFAVORITECOPIES.EDIT'"></i>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="footerButtons">
                        <button *appHasPermission="'MEDIAFAVORITECOPIES.EDIT'" (click)="addImeiCopyPair(mediaFavoriteCopyObject._id ?? '')" class="btn btn-success btn--icon-text" type="button">
                            <i class="zmdi zmdi-collection-plus"></i> {{ "mediafavoritecopies.list.addcopypair" | translate }}
                        </button>
                        <button *appHasPermission="'MEDIAFAVORITECOPIES.EDIT'" (click)="updateImeiCopyPairs(mediaFavoriteCopyObject._id ?? '')" class="btn btn-success btn--icon-text" type="button">
                            <i class="zmdi zmdi-check"></i> {{ "global.buttons.save" | translate }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

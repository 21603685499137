import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { ModalModule } from "ngx-bootstrap/modal";
import { NgModule, ModuleWithProviders } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";

import { TranslateModule } from "@ngx-translate/core";
import { OAuthModule } from "@impacgroup/angular-oauth-base";
import { Route, RouterModule } from "@angular/router";
import { MediaFavoriteCopiesComponent } from "./mediafavoritecopies.component";
import { MediaFavoriteCopiesService } from "./mediafavoritecopies.service";
import { IMediaFavoriteCopiesConfigRepository, MediaFavoriteCopiesConfigRepository, MediaFavoriteCopiesRepository } from "./mediafavoritecopies.repository";

@NgModule({
    declarations: [MediaFavoriteCopiesComponent],
    imports: [CommonModule, RouterModule, FormsModule, BsDropdownModule, ModalModule, NgSelectModule, TranslateModule, OAuthModule],
    exports: [],
    providers: []
})
export class MediaFavoriteCopiesModule {
    static forRoot(mediaFavoriteCopiesConfig: IMediaFavoriteCopiesConfigRepository): ModuleWithProviders<MediaFavoriteCopiesModule> {
        // returns the services as singletons throughout the app (for lazy loading purposes)
        return {
            ngModule: MediaFavoriteCopiesModule,
            providers: [
                MediaFavoriteCopiesService,
                MediaFavoriteCopiesRepository,
                {
                    provide: MediaFavoriteCopiesConfigRepository,
                    useValue: mediaFavoriteCopiesConfig
                }
            ]
        };
    }

    static getRoutes(): Route[] {
        return [
            {
                path: "",
                component: MediaFavoriteCopiesComponent
            }
        ];
    }
}

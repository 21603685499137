import { HttpClient, HttpContext, HttpParams } from "@angular/common/http";
import { Injectable, InjectionToken, Inject } from "@angular/core";
import { Observable } from "rxjs";
import { OAuthStorageAdapter } from "@impacgroup/angular-oauth-base";
import { BaseDatatableStateSaveMode } from "@impacgroup/angular-baselib";
import { map } from "rxjs/operators";
import { Expose, Type, plainToInstance } from "class-transformer";
import {
    V2AdminCameraApiServiceCreateOrReplace4GProCameraRequestDTO,
    V2AdminCameraApiServiceRead4GProCameraResponseDTO,
    V2AdminCameraApiServiceTriggerCommand4GProCameraRequestDTO,
    V2AdminCameraApiServiceUpdate4GProCameraRequestDTO
} from "@impacgroup/doerr-wildkamera-api-dtos";
import { IGNORE_SERVER_ERROR_CODE } from "src/app/global/api-handling/HttpErrorInterceptor";
import { ProductTypeKey } from "@impacgroup/doerr-wildkamera-api-shared";

export class CameraCorruptPictureOutputDTO {
    value?: number;

    @Expose()
    @Type(() => Date)
    date?: Date;
}

export interface ICamera4GProConfigRepository {
    cameras4GProAPI: string;
    cameras4GProWaAPI: string;
    cameras4GProRCAPI: string;
    cameras4GProRCWaAPI: string;
    cameras4GProMiniAPI: string;
    utcDateFormat: string;
    datatableStateSaveMode: BaseDatatableStateSaveMode;
}

export const Camera4GProConfigRepository = new InjectionToken<ICamera4GProConfigRepository>("CameraConfig");

@Injectable()
export class Cameras4GProRepository {
    public UTCDATEFORMAT: string = "";
    public datatableStateSaveMode: BaseDatatableStateSaveMode;

    constructor(@Inject(Camera4GProConfigRepository) private cameraConfig: ICamera4GProConfigRepository, private http: HttpClient, private oauthStorageAdapter: OAuthStorageAdapter) {
        this.UTCDATEFORMAT = this.cameraConfig.utcDateFormat;
        this.datatableStateSaveMode = this.cameraConfig.datatableStateSaveMode;
    }

    public createOrReplace(dto: V2AdminCameraApiServiceCreateOrReplace4GProCameraRequestDTO, productType: ProductTypeKey) {
        return this.http
            .post<V2AdminCameraApiServiceUpdate4GProCameraRequestDTO>(this.getCamerasServiceUrl({ productType }), dto, {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON()
            })
            .pipe(map((result) => plainToInstance(V2AdminCameraApiServiceRead4GProCameraResponseDTO, result)));
    }

    public read({ id, start, end, productType }: { id: string; start?: Date; end?: Date; productType: ProductTypeKey }): Observable<V2AdminCameraApiServiceRead4GProCameraResponseDTO> {
        let params = new HttpParams();

        if (start) {
            params = params.set("start", start.getTime());
        }

        if (end) {
            params = params.set("end", end.getTime());
        }

        return this.http
            .get<Object>(this.getCamerasServiceUrl({ postfix: id, productType }), {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON(),
                params
            })
            .pipe(map((result) => plainToInstance(V2AdminCameraApiServiceRead4GProCameraResponseDTO, result)));
    }

    public readCorruptStatistics({ id, start, end, productType }: { id: string; start?: Date; end?: Date; productType: ProductTypeKey }): Observable<CameraCorruptPictureOutputDTO[]> {
        let params = new HttpParams();

        if (start) {
            params = params.set("start", start.getTime());
        }

        if (end) {
            params = params.set("end", end.getTime());
        }

        return this.http
            .get<Object[]>(this.getCamerasServiceUrl({ postfix: `postfix: ${id}/corrupt`, productType }), {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON(),
                params
            })
            .pipe(map((result) => plainToInstance(CameraCorruptPictureOutputDTO, result)));
    }

    public update(id: string, dto: V2AdminCameraApiServiceUpdate4GProCameraRequestDTO, productType: ProductTypeKey): Observable<V2AdminCameraApiServiceRead4GProCameraResponseDTO> {
        return this.http
            .patch<V2AdminCameraApiServiceUpdate4GProCameraRequestDTO>(this.getCamerasServiceUrl({ postfix: id, productType }), dto, {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON()
            })
            .pipe(map((result) => plainToInstance(V2AdminCameraApiServiceRead4GProCameraResponseDTO, result)));
    }

    public sendCommand(id: string, dto: V2AdminCameraApiServiceTriggerCommand4GProCameraRequestDTO, productType: ProductTypeKey): Observable<V2AdminCameraApiServiceRead4GProCameraResponseDTO> {
        const headers = this.oauthStorageAdapter.getAuthHeadersJSON();

        // Ignore server error code and discard the error message
        const context = new HttpContext().set(IGNORE_SERVER_ERROR_CODE, ["camera_command_offline"]);

        return this.http
            .post<V2AdminCameraApiServiceTriggerCommand4GProCameraRequestDTO>(this.getCamerasServiceUrl({ postfix: id + "/command", productType }), dto, {
                headers,
                context
            })
            .pipe(
                map((result) => {
                    return plainToInstance(V2AdminCameraApiServiceRead4GProCameraResponseDTO, result, { excludeExtraneousValues: true });
                })
            );
    }

    public timezonelist(productType: ProductTypeKey): Observable<string[]> {
        const headers = this.oauthStorageAdapter.getAuthHeadersJSON();

        return this.http.get<string[]>(this.getCamerasServiceUrl({ postfix: "timezonelist", productType }), {
            headers
        });
    }

    private getCamerasServiceUrl({ postfix, productType }: { postfix?: string; productType: ProductTypeKey }): string {
        if (productType === "cloud_4g_pro") {
            return this.cameraConfig.cameras4GProAPI + (postfix ?? "");
        }
        if (productType === "cloud_4g_pro_wa") {
            return this.cameraConfig.cameras4GProWaAPI + (postfix ?? "");
        }
        if (productType === "cloud_4g_pro_rc") {
            return this.cameraConfig.cameras4GProRCAPI + (postfix ?? "");
        }
        if (productType === "cloud_4g_pro_rc_wa") {
            return this.cameraConfig.cameras4GProRCWaAPI + (postfix ?? "");
        }
        if (productType === "cloud_4g_pro_mini") {
            return this.cameraConfig.cameras4GProMiniAPI + (postfix ?? "");
        }
        throw new Error("Unsupported productType: " + productType);
    }
}

import { Component, OnInit, AfterViewInit } from "@angular/core";
import { trigger, state, style, animate, transition } from "@angular/animations";
import { TranslateService } from "@ngx-translate/core";
import { PermissionService, OAuthStorageAdapter } from "@impacgroup/angular-oauth-base";
import { Router } from "@angular/router";

type MenuItem = {
    title: string;
    icon?: string;
    permission?: string;
    route?: string;
    visibility?: string;
    sub?: MenuItem[];
};

@Component({
    selector: "app-sidebar",
    templateUrl: "sidebar.component.html",
    styleUrls: ["sidebar.component.scss"],
    host: {
        class: "sidebar"
    },
    animations: [
        trigger("toggleSubMenu", [
            state(
                "inactive",
                style({
                    height: "0",
                    opacity: "0"
                })
            ),
            state(
                "active",
                style({
                    height: "*",
                    opacity: "1"
                })
            ),
            transition("inactive => active", animate("200ms ease-in")),
            transition("active => inactive", animate("200ms ease-out"))
        ])
    ]
})
export class SidebarComponent implements OnInit, AfterViewInit {
    /* Main Menu

     * title: Main menu title
     * icon: Menu icon from material-design-iconic-fonts. Please refer "Icons" page for more details
     * route: Router link for page
     * sub: Sub menus
     * visibility: Property for animation. "inactive" means the sub menu is hidden by default.

     */
    mainMenu: MenuItem[] = [];

    tokenExpires: Date = new Date();

    // Toggle sub menu
    toggleSubMenu(i: number) {
        this.mainMenu[i].visibility = this.mainMenu[i].visibility === "inactive" ? "active" : "inactive";
    }

    constructor(private translate: TranslateService, private permissionService: PermissionService, private router: Router, private oauthStorageAdapter: OAuthStorageAdapter) {
        this.mainMenu = [
            {
                title: this.translate.instant("menu.home"),
                icon: "home",
                route: "/home",
                permission: "AUTHENTICATED"
            },
            {
                title: this.translate.instant("menu.user.title"),
                icon: "accounts-alt",
                permission: "MENU.USERMANAGEMENT",
                sub: [
                    {
                        title: this.translate.instant("menu.user.users"),
                        route: "/usermanagement/users",
                        permission: "MENU.USERMANAGEMENT.USERS"
                    },
                    {
                        title: this.translate.instant("menu.user.roles"),
                        route: "/usermanagement/roles",
                        permission: "MENU.USERMANAGEMENT.ROLES"
                    },
                    {
                        title: this.translate.instant("menu.user.organizations"),
                        route: "/usermanagement/organizations",
                        permission: "MENU.USERMANAGEMENT.ORGANIZATIONS"
                    }
                ],
                visibility: "inactive"
            },
            {
                title: this.translate.instant("menu.cameras"),
                icon: "camera",
                route: "/cameras",
                permission: "MENU.CAMERAS",
                visibility: "inactive"
            },
            {
                title: this.translate.instant("menu.firmwares.title"),
                icon: "smartphone-setup",
                permission: "MENU.FIRMWARE",
                visibility: "inactive",
                sub: [
                    {
                        title: this.translate.instant("menu.firmwares.overview"),
                        route: "/firmwares/overview",
                        permission: "MENU.FIRMWARES"
                    },
                    {
                        title: this.translate.instant("menu.firmwares.apns"),
                        route: "/firmwares/apns",
                        permission: "MENU.APNS"
                    }
                ]
            },
            {
                title: this.translate.instant("menu.imeis"),
                icon: "smartphone",
                route: "/imeis",
                permission: "MENU.IMEIS",
                visibility: "inactive"
            },
            {
                title: this.translate.instant("menu.languages"),
                icon: "flag",
                route: "/languages",
                permission: "MENU.LANGUAGES",
                visibility: "inactive"
            },
            {
                title: this.translate.instant("menu.firebase.title"),
                icon: "fire",
                permission: "MENU.FIREBASE",
                sub: [
                    {
                        title: this.translate.instant("menu.firebase.firebaseconfig"),
                        route: "/firebase/firebaseconfig",
                        permission: "MENU.FIREBASE.FIREBASECONFIG"
                    },
                    {
                        title: this.translate.instant("menu.firebase.firebasedevices"),
                        route: "/firebase/firebasedevices",
                        permission: "MENU.FIREBASE.FIREBASEDEVICES"
                    },
                    {
                        title: this.translate.instant("menu.firebase.firebasenotifications"),
                        route: "/firebase/firebasenotifications",
                        permission: "MENU.FIREBASE.FIREBASENOTIFICATIONS"
                    },
                    {
                        title: this.translate.instant("menu.firebase.firebasenotificationtemplates"),
                        route: "/firebase/firebasenotificationtemplates",
                        permission: "MENU.FIREBASE.FIREBASENOTIFICATIONTEMPLATES"
                    }
                ],
                visibility: "inactive"
            },
            {
                title: this.translate.instant("menu.mail.title"),
                icon: "email",
                permission: "MENU.MAIL",
                sub: [
                    {
                        title: this.translate.instant("menu.mail.mailaccounts"),
                        route: "/mail/mailaccounts",
                        permission: "MENU.MAIL.MAILACCOUNTS"
                    },
                    {
                        title: this.translate.instant("menu.mail.mailtemplates"),
                        route: "/mail/mailtemplates",
                        permission: "MENU.MAIL.MAILTEMPLATES"
                    }
                ],
                visibility: "inactive"
            },
            {
                title: this.translate.instant("menu.payment.title"),
                icon: "money-box",
                permission: "MENU.PAYMENT",
                sub: [
                    {
                        title: this.translate.instant("menu.payment.appleconfig"),
                        route: "/payment/appleconfig",
                        permission: "MENU.PAYMENT.APPLECONFIG"
                    },
                    {
                        title: this.translate.instant("menu.payment.googleconfig"),
                        route: "/payment/googleconfig",
                        permission: "MENU.PAYMENT.GOOGLECONFIG"
                    },
                    {
                        title: this.translate.instant("menu.payment.klarnaconfig"),
                        route: "/payment/klarnaconfig",
                        permission: "MENU.PAYMENT.KLARNACONFIG"
                    },
                    {
                        title: this.translate.instant("menu.payment.klarnaaboplans"),
                        route: "/payment/klarnaaboplans",
                        permission: "MENU.PAYMENT.KLARNAABOPLANS"
                    },
                    {
                        title: this.translate.instant("menu.payment.klarnaaboagreements"),
                        route: "/payment/klarnaaboagreements",
                        permission: "MENU.PAYMENT.KLARNAABOAGREEMENTS"
                    },
                    {
                        title: this.translate.instant("menu.payment.paypalconfig"),
                        route: "/payment/paypalconfig",
                        permission: "MENU.PAYMENT.PAYPALCONFIG"
                    },
                    {
                        title: this.translate.instant("menu.payment.paypalpayments"),
                        route: "/payment/paypalpayments",
                        permission: "MENU.PAYMENT.PAYPALPAYMENTS"
                    },
                    {
                        title: this.translate.instant("menu.payment.paypalbillingplans"),
                        route: "/payment/paypalbillingplans",
                        permission: "MENU.PAYMENT.PAYPALBILLINGPLANS"
                    },
                    {
                        title: this.translate.instant("menu.payment.paypalbillingagreements"),
                        route: "/payment/paypalbillingagreements",
                        permission: "MENU.PAYMENT.PAYPALBILLINGAGREEMENTS"
                    }
                ],
                visibility: "inactive"
            },
            {
                title: this.translate.instant("menu.registration"),
                icon: "settings",
                route: "/registrationconfig",
                permission: "MENU.REGISTRATION",
                visibility: "inactive"
            },
            {
                title: this.translate.instant("menu.sms.title"),
                icon: "phone-msg",
                permission: "MENU.SMS",
                sub: [
                    {
                        title: this.translate.instant("menu.sms.websmsconfig"),
                        route: "/sms/websmsconfig",
                        permission: "MENU.SMS.WEBSMSCONFIG"
                    },
                    {
                        title: this.translate.instant("menu.sms.websmsnotifications"),
                        route: "/sms/websmsnotifications",
                        permission: "MENU.SMS.WEBSMSNOTIFICATIONS"
                    }
                ],
                visibility: "inactive"
            },
            {
                title: this.translate.instant("menu.stolencameras"),
                icon: "camera",
                route: "/stolencameras",
                permission: "MENU.STOLENCAMERAS",
                visibility: "inactive"
            },
            {
                title: this.translate.instant("menu.abousers"),
                icon: "repeat",
                route: "/abousers",
                permission: "MENU.ABOUSERS",
                visibility: "inactive"
            },
            {
                title: this.translate.instant("menu.textpools"),
                icon: "collection-text",
                route: "/textpools",
                permission: "MENU.TEXTPOOLS",
                visibility: "inactive"
            },
            {
                title: this.translate.instant("menu.mediafavoritecopies"),
                icon: "copy",
                route: "/mediafavoritecopies",
                permission: "MENU.MEDIAFAVORITECOPIES",
                visibility: "inactive"
            },
            {
                title: this.translate.instant("menu.systemhealth.title"),
                icon: "dns",
                permission: "MENU.SYSTEMHEALTH",
                sub: [
                    {
                        title: this.translate.instant("menu.systemhealth.systemerrors"),
                        route: "/systemhealth/errors",
                        permission: "MENU.SYSTEMHEALTH.SYSTEMERRORS"
                    },
                    {
                        title: this.translate.instant("menu.systemhealth.systemlogmessages"),
                        route: "/systemhealth/logs",
                        permission: "MENU.SYSTEMHEALTH.SYSTEMLOGMESSAGES"
                    },
                    {
                        title: this.translate.instant("menu.systemhealth.systemjobperformances"),
                        route: "/systemhealth/jobs",
                        permission: "MENU.SYSTEMHEALTH.SYSTEMJOBPERFORMANCES"
                    }
                ],
                visibility: "inactive"
            },
            {
                title: this.translate.instant("menu.crashlogs.title"),
                icon: "alert-octagon",
                permission: "MENU.CRASHLOGS",
                sub: [
                    {
                        title: this.translate.instant("menu.crashlogs.crashlogapplications"),
                        route: "/crashlogs/crashlogapplications",
                        permission: "MENU.CRASHLOGS.CRASHLOGAPPLICATIONS"
                    },
                    {
                        title: this.translate.instant("menu.crashlogs.crashlogerrors"),
                        route: "/crashlogs/crashlogerrors",
                        permission: "MENU.CRASHLOGS.CRASHLOGERRORS"
                    },
                    {
                        title: this.translate.instant("menu.crashlogs.crashlogmessages"),
                        route: "/crashlogs/crashlogmessages",
                        permission: "MENU.CRASHLOGS.CRASHLOGMESSAGES"
                    }
                ],
                visibility: "inactive"
            }
        ];
        this.filterByPermissions();
        this.tokenExpires = new Date(this.oauthStorageAdapter.getOAuthLoginResult()?.accessTokenExpiresAt ?? 0);
    }

    ngOnInit() {}

    private filterByPermissions(): void {
        const filteredMenu = this.mainMenu.filter((menu) => {
            if (menu.sub && menu.sub.length > 0) {
                const filteredSubmenu = menu.sub.filter((sub) => this.permissionService.hasPermission(sub.permission ?? ""));
                menu.sub = filteredSubmenu;
            }
            return this.permissionService.hasPermission(menu.permission ?? "");
        });
        this.mainMenu = filteredMenu;
    }

    ngAfterViewInit() {
        this.mainMenu.forEach((mainMenuNode) => {
            if (mainMenuNode.sub) {
                mainMenuNode.sub.forEach((subMenuNode) => {
                    if (this.router.isActive(subMenuNode.route ?? "", false)) {
                        setTimeout(() => {
                            mainMenuNode.visibility = "active";
                        }, 0);
                    }
                });
            }
        });
    }
}

<ng-template #bulkUploadModalTemplate>
    <div class="modal-header">
        <h4 class="modal-title">{{ "imeis.list.bulkUploadModal.title" | translate }}</h4>
    </div>
    <div class="modal-body row">
        <div class="col-md-12">
            <div class="form-group">
                <label>{{ "imeis.props.productType" | translate }}</label>
                <ng-select name="productType" [items]="productTypes" placeholder="" bindLabel="name" bindValue="key" [clearable]="false" [(ngModel)]="selectedProductTypeKey"> </ng-select>
                <i class="form-group__bar"></i>
                <div class="invalid-tooltip">
                    {{ "imeis.detail.validation.productType" | translate }}
                </div>
            </div>
        </div>
        <input #fileInput type="file" (change)="handleFileInput($event)" style="display: none" />
    </div>
    <div class="modal-footer">
        <button class="btn btn-link" (click)="fileInput.click()">{{ "imeis.list.upload" | translate }}</button>
        <button class="btn btn-link" (click)="bulkUploadModalRef?.hide()">{{ "global.buttons.close" | translate }}</button>
    </div>
</ng-template>

<ng-template #csvExportModalTemplate>
    <div class="modal-header">
        <h4 class="modal-title">{{ "imeis.list.csvExportModal.title" | translate }}</h4>
    </div>
    <div class="modal-body row">
        <div class="col-md-12">
            <div class="form-group">
                <label>{{ "imeis.props.productType" | translate }}</label>
                <ng-select name="productType" [items]="productTypes" placeholder="" bindLabel="name" bindValue="key" [clearable]="false" [(ngModel)]="selectedProductTypeKey"> </ng-select>
                <i class="form-group__bar"></i>
                <div class="invalid-tooltip">
                    {{ "imeis.detail.validation.productType" | translate }}
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-link" (click)="exportCsv()">{{ "imeis.list.export" | translate }}</button>
        <button class="btn btn-link" (click)="csvExportModalRef?.hide()">{{ "global.buttons.close" | translate }}</button>
    </div>
</ng-template>

<div class="content__inner">
    <header class="content__title">
        <h1>{{ "imeis.list.title" | translate }}</h1>
        <small>{{ "imeis.list.subtitle" | translate }}</small>

        <div class="actions">
            <button *appHasPermission="'IMEIS.ADD'" (click)="openBulkImportModal()" class="btn btn-success btn--icon-text" type="button"><i class="zmdi zmdi-collection-plus"></i> {{ "imeis.list.upload" | translate }}</button>
            <button *appHasPermission="'IMEIS.ADD'" (click)="addImei()" class="btn btn-success btn--icon-text" style="margin-left: 25px"><i class="zmdi zmdi-collection-plus"></i> {{ "imeis.list.add" | translate }}</button>
            <button *appHasPermission="'IMEIS.LIST'" (click)="openExportModal()" class="btn btn-success btn--icon-text" style="margin-left: 25px"><i class="zmdi zmdi-download"></i> {{ "imeis.list.export" | translate }}</button>
        </div>
    </header>

    <div class="card" *appHasPermission="'IMEIS.LIST'">
        <div class="card-body">
            <h4 class="card-title">{{ "imeis.list.card.title" | translate }}</h4>

            <table datatable class="table table-bordered" [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                <thead>
                    <tr>
                        <th class="w-0" id="realtimeStatus"></th>
                        <th id="code">{{ "imeis.props.code" | translate }}</th>
                        <th id="productType">{{ "imeis.props.productType" | translate }}</th>
                        <th id="created">{{ "imeis.props.created" | translate }}</th>
                        <th id="updated">{{ "imeis.props.updated" | translate }}</th>
                        <th id="actions"></th>
                    </tr>
                </thead>
                <tbody *ngIf="rows?.length != 0">
                    <tr *ngFor="let row of rows">
                        <td>
                            <span
                                *ngIf="row.realtimeConnectionEstablished === true || row.realtimeConnectionEstablished === false"
                                class="rounded-circle rtcDot"
                                [class.bg-success]="row.realtimeConnectionEstablished"
                                [class.bg-danger]="!row.realtimeConnectionEstablished"
                            ></span>
                        </td>
                        <td>{{ row.code }}</td>
                        <td>{{ row.productType }}</td>
                        <td>{{ row.created | date : UTCDATEFORMAT : "+0" }}</td>
                        <td>{{ row.updated | date : UTCDATEFORMAT : "+0" }}</td>
                        <td class="text-right nowrap">
                            <i (click)="editImei(row._id)" class="zmdi zmdi-edit zmdi-hc-fw tableIcon" *appHasPermission="'IMEIS.EDIT'"></i>
                            <i (click)="deleteImei(row._id)" class="zmdi zmdi-delete zmdi-hc-fw tableIcon" *appHasPermission="'IMEIS.DELETE'"></i>
                        </td>
                    </tr>
                </tbody>
                <tbody *ngIf="rows?.length == 0">
                    <tr>
                        <td colspan="4" class="no-data-available">{{ "global.datatable.empty" | translate }}</td>
                    </tr>
                </tbody>

                <tbody></tbody>
            </table>
        </div>
    </div>
</div>

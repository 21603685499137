import { Injectable } from "@angular/core";
import { Observable, OperatorFunction } from "rxjs";
import { map } from "rxjs/operators";
import { plainToInstance } from "class-transformer";
import { MediaFavoriteCopy } from "./mediafavoritecopies.viewmodel";
import { MediaFavoriteCopiesRepository } from "./mediafavoritecopies.repository";
import { V2AdminMediaFavoriteCopyListResponseDTO, V2AdminMediaFavoriteCopyUpdateRequestDTO, V2AdminMediaFavoriteCopyUserCreateRequestDTO, V2AdminMediaFavoriteCopyUserDeleteRequestDTO } from "@impacgroup/doerr-wildkamera-api-dtos";

@Injectable()
export class MediaFavoriteCopiesService {
    constructor(private mediaFavoriteCopiesRepository: MediaFavoriteCopiesRepository) {}

    public mediaCopyPairs(): Observable<MediaFavoriteCopy[]> {
        return this.mediaFavoriteCopiesRepository.list().pipe(this.convertDTOtoVM());
    }

    public createMediaCopyUser(email: string): Observable<MediaFavoriteCopy[]> {
        const dto = plainToInstance(V2AdminMediaFavoriteCopyUserCreateRequestDTO, { userEmail: email }, { excludeExtraneousValues: true });
        return this.mediaFavoriteCopiesRepository.createMediaCopyUser(dto).pipe(this.convertDTOtoVM());
    }

    public deleteMediaCopyUser(id?: string): Observable<MediaFavoriteCopy[]> {
        if (!id) {
            throw new Error("Cannot delete object without _id");
        }

        const dto = plainToInstance(V2AdminMediaFavoriteCopyUserDeleteRequestDTO, { _id: id }, { excludeExtraneousValues: true });
        return this.mediaFavoriteCopiesRepository.deleteMediaCopyUser(dto).pipe(this.convertDTOtoVM());
    }

    public updateMediaCopyPairs(objectToUpdate: MediaFavoriteCopy) {
        if (!objectToUpdate._id) {
            throw new Error("Cannot update object without _id");
        }

        const dto = plainToInstance(V2AdminMediaFavoriteCopyUpdateRequestDTO, { ...objectToUpdate }, { excludeExtraneousValues: true });
        return this.mediaFavoriteCopiesRepository.updateMediaCopyPairs(dto).pipe(this.convertDTOtoVM());
    }

    private convertDTOtoVM(): OperatorFunction<V2AdminMediaFavoriteCopyListResponseDTO[], MediaFavoriteCopy[]> {
        return map((result) => {
            return result.map((item) => {
                return {
                    ...item,
                    show: false,
                    user: {
                        _id: item.user?._id ?? "",
                        email: item.user?.email ?? ""
                    },
                    imeiCopyPairs: item.imeiCopyPairs?.map((pair) => {
                        return {
                            fromImei: pair.fromImei ?? "",
                            toImei: pair.toImei ?? "",
                            editable: false
                        };
                    })
                };
            });
        });
    }
}

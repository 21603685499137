import {
    ProAutoscaleMode,
    ProBatteryType,
    ProCameraMode,
    ProFlashPower,
    ProImageSize,
    ProMultiShot,
    ProMultiShotInterval,
    ProNightMode,
    ProPIRSensitivity,
    ProRemoteControl,
    ProSecurityMode,
    ProSendingMode,
    ProVideoSize,
    ProductTypeKey
} from "@impacgroup/doerr-wildkamera-api-shared";
import * as moment from "moment";

export class CameraCreateOrReplace4GPro {
    _id?: string;
    imei?: string;
    name?: string;
    productType?: ProductTypeKey;
    firmware?: string;
    realtimeConnectionEstablished?: boolean;
    batteryLow?: boolean;
    batteryLevelReadable?: string;
    userId?: string;
    stolen?: string;
    proSettings?: CameraDetail4GProSettings;
    corrupt?: {
        pictures?: CorruptPicture[];
        picturesReadable?: number;
    };
    showAutoscaleSetting?: boolean;
    availableProBatteryTypes?: number[];
    availableProImageSizes?: number[];
    availableProMultiShots?: number[];
}

export type CameraDetail4GPro = {
    _id?: string;
    imei?: string;
    name?: string;
    productType?: ProductTypeKey;
    firmware?: string;
    realtimeConnectionEstablished?: boolean;
    isUninitialized?: boolean;
    batteryLow?: boolean;
    batteryLevelReadable?: string;
    owner?: CameraDetail4GProGenericUser;
    ownerReadable?: string;
    stolen?: string;
    availableCommands: CameraDetail4GProAvailableCommands;
    proSettings?: CameraDetail4GProSettings;
    corrupt?: {
        pictures?: CorruptPicture[];
        picturesReadable?: number;
    };
    settingsMissmatch?: boolean;
    firmwareUpdateMinBatteryLevel?: number;
    firmwareUpdateBatteryLow?: boolean;
    csqStatus?: string;
    signalStrength?: number;
    powered?: boolean;
    latestSyncUpdate?: Date;
    latestMediaUpload?: Date;
    settingsSynchronized?: boolean;
    availableQuotaMB?: number;
    usedQuotaMB?: number;
    apn?: string;
    apnMccMmc?: string;
    apnUser?: string;
    apnPassword?: string;
    reportFailedPictureUpload?: boolean;
    reportFailedVideoUpload?: boolean;
    showAutoscaleSetting?: boolean;
    sdCapacity?: string;
    sdcardFull?: boolean;
    availableProBatteryTypes?: number[];
    isBatteryLevelCharging?: boolean;
    availableProImageSizes?: number[];
    availableProMultiShots?: number[];
};

export declare class CameraDetail4GProGenericUser {
    _id?: string;
    email?: string;
    firstname?: string;
    lastname?: string;
}

export type CameraDetail4GProAvailableCommands = {
    init?: boolean;
    takePicture?: boolean;
    takeVideo?: boolean;
    firmwareUpdate?: boolean;
    cameraRestart?: boolean;
    formatSDCard?: boolean;
    cameraTurnOn?: boolean;
    cameraTurnOff?: boolean;
    cameraReset?: boolean;
    sendStatus?: boolean;
    syncSettings?: boolean;
};

export type CameraDetail4GProSettings = {
    cameraMode?: ProCameraMode;
    imageSize?: ProImageSize;
    videoSize?: ProVideoSize;
    videoLength?: number;
    multiShot?: ProMultiShot;
    multiShotInterval?: ProMultiShotInterval;
    nightMode?: ProNightMode;
    batteryType?: ProBatteryType;
    flashPower?: ProFlashPower;
    timezoneIdentifier?: string;
    delayActive?: boolean;
    delayHH?: string;
    delayMM?: string;
    delaySS?: string;
    timeLapseActive?: boolean;
    timeLapseHH?: string;
    timeLapseMM?: string;
    timeLapseSS?: string;
    workTimer1Active?: boolean;
    workTimer1From?: Date;
    workTimer1To?: Date;
    workTimer2Active?: boolean;
    workTimer2From?: Date;
    workTimer2To?: Date;
    sdLoop?: boolean;
    maxNumPerDayActive?: boolean;
    maxNumPerDay?: number;
    pirSensitivity?: ProPIRSensitivity;
    remoteControl?: ProRemoteControl;
    gpsMode?: boolean;
    sendingMode?: ProSendingMode;
    securityMode?: ProSecurityMode;
    autoscaleMode?: boolean;
};

export interface CorruptPicture {
    value: number;
    date: Date;
    meta: string;
}

export class CameraDetail4GProHelper {
    public static defaultSettings = (): Required<CameraDetail4GProSettings> => {
        return {
            cameraMode: ProCameraMode.photo,
            imageSize: ProImageSize["8MP"],
            videoSize: ProVideoSize["WVGA"],
            videoLength: 10,
            multiShot: ProMultiShot["1p"],
            multiShotInterval: 1,
            nightMode: ProNightMode.maxRange,
            batteryType: ProBatteryType.alkaline,
            flashPower: ProFlashPower.high,
            timezoneIdentifier: Intl.DateTimeFormat().resolvedOptions().timeZone,
            delayActive: false,
            delayHH: "00",
            delayMM: "00",
            delaySS: "05",
            timeLapseActive: false,
            timeLapseHH: "00",
            timeLapseMM: "00",
            timeLapseSS: "05",
            workTimer1Active: false,
            workTimer1From: moment("00:00:00").toDate(),
            workTimer1To: moment("00:00:00").toDate(),
            workTimer2Active: false,
            workTimer2From: moment("00:00:00").toDate(),
            workTimer2To: moment("00:00:00").toDate(),
            sdLoop: true,
            maxNumPerDayActive: false,
            maxNumPerDay: 0,
            pirSensitivity: 7,
            remoteControl: "RT",
            gpsMode: true,
            sendingMode: ProSendingMode.photo,
            securityMode: ProSecurityMode.noSecurity,
            autoscaleMode: true
        };
    };
}

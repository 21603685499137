import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Router, ActivatedRoute } from "@angular/router";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { AlertService, BaseComponent } from "@impacgroup/angular-baselib";
import { MediaFavoriteCopiesService } from "./mediafavoritecopies.service";
import { MediaFavoriteCopy } from "./mediafavoritecopies.viewmodel";

@Component({
    selector: "app-mediafavoritecopies",
    templateUrl: "./mediafavoritecopies.component.html",
    styleUrls: ["mediafavoritecopies.component.scss"]
})
export class MediaFavoriteCopiesComponent extends BaseComponent implements OnInit {
    mediaFavoriteCopyObjects: MediaFavoriteCopy[] = [];

    @ViewChild("addUserModalTemplate")
    public addUserModalTemplate?: TemplateRef<any>;
    public addUserModalRef?: BsModalRef;

    public userEmailToAdd: string = "";

    constructor(
        private mediaFavoriteCopiesService: MediaFavoriteCopiesService,
        private translateService: TranslateService,
        private modalService: BsModalService,
        private router: Router,
        private route: ActivatedRoute,
        private alertService: AlertService
    ) {
        super();
    }

    ngOnInit() {
        this.subscriptions.push(
            this.mediaFavoriteCopiesService.mediaCopyPairs().subscribe({
                next: (result) => {
                    this.mediaFavoriteCopyObjects = result;
                },
                error: (err) => {
                    this.router.navigate(["../"], { relativeTo: this.route });
                }
            })
        );
    }

    openAddUserModal() {
        if (this.addUserModalTemplate) {
            this.addUserModalRef = this.modalService.show(this.addUserModalTemplate);
        }
    }

    addUser() {
        this.subscriptions.push(
            this.mediaFavoriteCopiesService.createMediaCopyUser(this.userEmailToAdd).subscribe({
                next: (result) => {
                    this.mediaFavoriteCopyObjects = result;
                    this.alertService.addSuccess(this.translateService.instant("mediafavoritecopies.list.addUserModal.success"));
                }
            })
        );
        this.addUserModalRef?.hide();
    }

    deleteUser(id?: string) {
        console.log(id);
        this.subscriptions.push(
            this.mediaFavoriteCopiesService.deleteMediaCopyUser(id).subscribe({
                next: (result) => {
                    this.mediaFavoriteCopyObjects = result;
                    this.alertService.addSuccess(this.translateService.instant("mediafavoritecopies.list.delete.success"));
                }
            })
        );
    }

    addImeiCopyPair(objectId: string) {
        const objectIdx = this.mediaFavoriteCopyObjects.findIndex((item) => item._id === objectId);
        if (objectIdx >= 0 && objectIdx < this.mediaFavoriteCopyObjects.length) {
            this.mediaFavoriteCopyObjects[objectIdx].imeiCopyPairs?.push({ fromImei: "", toImei: "", editable: true });
        }
    }

    editImeiCopyPair(index: number, objectId: string) {
        const objectIdx = this.mediaFavoriteCopyObjects.findIndex((item) => item._id === objectId);
        if (objectIdx >= 0 && objectIdx < this.mediaFavoriteCopyObjects.length) {
            if (index < (this.mediaFavoriteCopyObjects[objectIdx].imeiCopyPairs ?? []).length) {
                this.mediaFavoriteCopyObjects[objectIdx].imeiCopyPairs![index].editable = !this.mediaFavoriteCopyObjects[objectIdx].imeiCopyPairs![index].editable;
            }
        }
    }

    deleteImeiCopyPair(index: number, objectId: string) {
        const objectIdx = this.mediaFavoriteCopyObjects.findIndex((item) => item._id === objectId);
        if (objectIdx >= 0 && objectIdx < this.mediaFavoriteCopyObjects.length) {
            if (index < (this.mediaFavoriteCopyObjects[objectIdx].imeiCopyPairs ?? []).length) {
                this.mediaFavoriteCopyObjects[objectIdx].imeiCopyPairs!.splice(index, 1);
            }
        }
    }

    showHideImeiCopyPairs(objectId: string) {
        const objectIdx = this.mediaFavoriteCopyObjects.findIndex((item) => item._id === objectId);
        if (objectIdx >= 0 && objectIdx < this.mediaFavoriteCopyObjects.length) {
            this.mediaFavoriteCopyObjects[objectIdx].show = !this.mediaFavoriteCopyObjects[objectIdx].show;
        }
    }

    updateImeiCopyPairs(objectId: string) {
        const objectIdx = this.mediaFavoriteCopyObjects.findIndex((item) => item._id === objectId);
        if (objectIdx >= 0 && objectIdx < this.mediaFavoriteCopyObjects.length) {
            this.mediaFavoriteCopiesService.updateMediaCopyPairs(this.mediaFavoriteCopyObjects[objectIdx]).subscribe({
                next: (result) => {
                    this.mediaFavoriteCopyObjects = result;
                    this.alertService.addSuccess(this.translateService.instant("mediafavoritecopies.list.update.success"));
                }
            });
        }
    }
}
